import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as applications from 'services/applications'
import actions from './actions'

export function* GET_ALL({ payload }) {
  const { pagination } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
    },
  })

  const { current, pageSize } = pagination

  const limit = pageSize
  const offset = (current - 1) * pageSize

  const response = yield call(applications.getAll, limit, offset)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response.list,
        total: parseInt(response.total, 10),
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ALL, GET_ALL)])
}
