import apiClient from 'services/axios'

// eslint-disable-next-line import/prefer-default-export
export async function getAll(limit, offset) {
  const url = `/applications?limit=${limit}&offset=${offset}`

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return {
          list: response.data,
          total: response.headers['content-range'].split('/')[1],
        }
      }
      return false
    })
    .catch(err => console.log(err))
}
